import axios from 'axios';
import { useLogin } from '../context/LoginContext';

const axiosServices = axios.create();

axiosServices.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token');
    config.headers["Authorization"] = `Bearer ${token}`
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosServices.interceptors.response.use(
  (response) => {
    //return updateImageLinks(response);
    return response;
  },
  (error) => {
    const {response} = error
    if(response.status == 401 && response.data.message === 'token invalido'){
      sessionStorage.removeItem('token');
      requestLogin();

    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;

const requestLogin = () => {
  console.log('requestLogin login')
  // const { updateModalState } = useLogin(); 
  //useLogin.updateModalState(true);
}


const updateImageLinks = (obj) => {
  let copy;

  if (Array.isArray(obj)) {
    copy = obj.map(item => updateImageLinks(item));
  } else if (typeof obj === 'object' && obj !== null) {
    copy = {};
    for (let key in obj) {
      if (key === 'image' && typeof obj[key] === 'string') {
        const randomTimestamp = new Date().getTime();
        const randomNumberBetween1and100 = Math.floor(Math.random() * 100) + 1;
        copy[key] = `${obj[key]}?random=${randomTimestamp}-${randomNumberBetween1and100}`;
      } else {
        copy[key] = updateImageLinks(obj[key]);
      }
    }
  } else {
    return obj;
  }

  return copy;
};


