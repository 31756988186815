import {useContext, createContext, useState, useEffect} from "react";
import {GetSessionInfo, LogIn} from "../api/authManagerQueries";

const AuthContext = createContext({
    isAuthenticated: false,
    setSession: () => {
    },
    logOut: () => {
    },
    getToken: () => {
    },
    setToken: () => {
    },
    sessionInfo: null,
});

export function AuthProvider({children}) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [sessionInfo, setSessionInfo] = useState(null);

    async function SignIn(userData) {
        const result = await LogIn(userData)
        if (result.data?.token) {
            setSession(result.data.token)
        }
        return result
    };

    async function getSessionInformation() {
        const result = await GetSessionInfo()
        console.log("SESSION INFO", result)
        if (result.data) {
            setSessionInfo(result.data)
        }
        if (result.token) {
            sessionStorage.setItem('token', result.token);
        }
        return result
    };

    function setSession(token) {
        sessionStorage.setItem('token', token);
        setIsAuthenticated(true);
        getSessionInformation()
    }

    function logOut() {
        sessionStorage.removeItem('token');
        setIsAuthenticated(false);
    }

    function getToken() {
        return sessionStorage.getItem('token');
    }

    function setToken(token) {
        return sessionStorage.setItem('token', token);
    }

    function checkAuth() {
        const token = sessionStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }

    useEffect(() => {
        checkAuth();
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider value={{isAuthenticated, setSession, SignIn, logOut, getToken, setToken, sessionInfo}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);