import React, {useEffect, useState} from "react";

import { Link, useNavigate } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/profile/17.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown, Button } from "react-bootstrap";
import { useAuth } from "../../../auth/AuthProvider";
import SearchBox from "./SearchBox";

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {

  const [sessionData, setSessionData] = useState(null);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
    const navigate = useNavigate();
    const auth = useAuth();
    const {sessionInfo} = auth
    const logOut = () =>{
      auth.logOut();
      navigate('/login');
    }

  useEffect(() => {
    if (sessionInfo && sessionInfo.data) {
      console.log(sessionInfo)
      setSessionData(sessionInfo.data[0]);
    }
  }, [sessionInfo]);
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {/* {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ")} */}
                  OTT Manager
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <li className="nav-item">
                  <SearchBox />
              </li>

              <Dropdown className="nav-item dropdown header-profile ml-sm-4 ml-2">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <div className="header-info">
                    <span className="text-black">
                      <strong>Nuestro Cine MX</strong>
                    </span>
                    {sessionData ? <p className="fs-12 mb-0">{sessionData.name} {sessionData.last_name}</p>
                        : <></>}
                  </div>
                  <img src="https://images.nuestrocine.mx/imcine-images/f6056efbe719c65d860e94bf11b64076.png" width={20} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">

                  <Button variant="" className='dropdown-item ai-icon' onClick={()=> logOut()}>
                        <svg
                          id="icon-logout"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-danger"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                          <polyline points="16 17 21 12 16 7" />
                          <line x1={21} y1={12} x2={9} y2={12} />
                        </svg>
                        <span className="ml-2">Cerrar sesión </span>
                    </Button>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
