import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { config } from "./config/config";

import { SearchProvider } from './context/SearchContext'; // Import the SearchProvider

const queryClient = new QueryClient({
  defaultOptions:{
    queries: {
      staleTime: 0,
      cacheTime: 0 ,
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <SimpleReactLightbox>
      <SearchProvider>
        <App />
      </SearchProvider>
    </SimpleReactLightbox>
  </QueryClientProvider>
  ,document.getElementById("root")
);

reportWebVitals();
