import {getApiConfig} from '../helpers/configHelper';
import axios from '../utils/axios';

const {endpoint, auth_service, login, sessionInfo} = getApiConfig();
const headers = {"Content-Type": "multipart/form-data"}

export const LogIn = async (userData) => {
    const response = await axios.post(`${endpoint}/${auth_service}/${login}/`, userData, {headers: headers})
    return response
}

export const GetSessionInfo = async () => {
    const response = await axios.get(`${endpoint}/${auth_service}/${sessionInfo}/`,
        {headers: headers})
    return response
}