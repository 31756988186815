import { config } from "../config/config";

export const getApiConfig = () => {
    return config.api;
}

/* Obtenemos la configuracion de API necesaria para las estádisticas */
export const getStatisticsApiConfig = () => {
    return {
        endpoint: config.api.endpoint,
        mix_service: config.api.mix_service
    };
};

/* Obtenemos la configuracion de API necesaria para los códigos promocionales */
export const getPromoCodesApiConfig = () => {
    return {
        endpoint: config.api.endpoint,
        mix_service: config.api.mix_service,
        organization: config.organization_id,
        metadata_service: config.api.metadata_service,
        payment_service: config.api.payment_service
    };
};

/* Obtenemos la configuracion de API necesaria para los comentarios y ratings */
export const getRatingsApiConfig = () => {
    return {
        endpoint: config.api.endpoint,
        mix_service: config.api.mix_service,
        organization: config.organization_id
    };
}

/* Obtenemos la configuracion de API necesaria para la gestión de
banners y bloques de contenido del home*/
export const getHomeManagementConfig = () => {
    return {
        endpoint: config.api.endpoint,
        metadata_service: config.api.metadata_service,
        organization: config.organization_id,
        images_service: config.api.images_service
    };
};

/* OBTIENE endpoints para el blog */
export const getBlogConfig = () => {
    return {
        endpoint: config.api.endpoint,
        metadata_service: config.api.metadata_service
    };
};

export const getAwardsConfig = () => {
    return {
        endpoint: config.api.endpoint,
        metadata_service: config.api.metadata_service,
    }
}  
/* OBTIENE endpoints para el blog */
export const getExtrasConfig = () => {
    return {
        endpoint: config.api.endpoint,
        metadata_service: config.api.metadata_service
    };
};

export const getDownloadableReportsConfig = () => {
    return {
        endpoint: config.api.endpointUpload,
        mix_service: config.api.mix_service,
    };
}