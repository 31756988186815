import React, { Fragment, useState, useRef, useEffect} from "react";
import { Table, Pagination, Alert, Dropdown, Modal, Button } from "react-bootstrap";
import Login from '../../jsx/pages/Login';

import { useLogin } from "../../context/LoginContext";

const ModalLogin = () => {
    const { loginModal } = useLogin();

    const [newModal, setNewModal] = useState(false);

    useEffect(() => { // This will run every time the searchTerm changes
        console.log('showLoginModal', loginModal)
        if(loginModal){
            console.log('showing modal')
            setNewModal(true);
        } else {
            console.log('closing modal')
            setNewModal(false);
        }
    }, [loginModal]);

    return (
        <Fragment>
            <Modal className="fade bd-example-modal-lg" show={newModal} size="xl">
                <Modal.Header>
                    {/* <Modal.Title></Modal.Title> */}
                    <Button variant="" className="close" onClick={() => setNewModal(false)}><span>&times;</span></Button>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Login modalMode={true}/>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="btn btn-xs btn-danger light" onClick={() => setNewModal(false)}>Cancelar</Button>
                    <Button variant="" type="button" className="btn btn-xs btn-primary">Guardar</Button>
                </Modal.Footer> */}
            </Modal>
        </Fragment>
        
    );
}
export default ModalLogin;