import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export function useSearch() {
    return useContext(SearchContext);
}

export const SearchProvider = ({ children }) => {
    // Now we will use an object to hold the search term and the clean state.
    const [searchAttributes, setSearchAttributes] = useState({
        searchTerm: '',
        searchIsCleaned: true
    });

    // Function to update both the search term and the clean indicator.
    const updateSearchTerm = (term) => {
        setSearchAttributes({
            searchTerm: term,
            searchIsCleaned: term.trim() === ''  // True if the term is empty
        });
    };

    return (
        <SearchContext.Provider value={{ ...searchAttributes, updateSearchTerm }}>
            {children}
        </SearchContext.Provider>
    );
};
