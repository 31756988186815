import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap'

import { useAuth } from '../../auth/AuthProvider';

import logo from '../../images/LOGO-VIVARO-DIGITAL.png'
import { set } from 'date-fns';

const Login = ({ history, modalMode }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const {register, handleSubmit, formState: {errors}} = useForm();
  const [error, setError] = useState(null)

  const onSubmit = async (formData) => {
    console.log("sumbit",formData);

    try {
      const {data} = await auth.SignIn(formData);
      console.log('login response', data);
      if (data.token) {
        console.log('navigate')
        setError(null);
        navigate('/movies');
      }
    } catch (error) {
      console.error(error)
      setError(error.description);
    }
  }


  if (auth.isAuthenticated && !modalMode) {
    // navigate(-1);
    // return <></>
     return <Navigate to="/"/>;
  }

  return (
    // <div className='authincation h-100 p-meddle'>
    //   <div className='container h-100'>

    <div className='row justify-content-center h-100 align-items-center'>
      <div className='col-md-6'>
        <div className='authincation-content'>
          <div className='row no-gutters'>
            <div className='col-xl-12'>
              <div className='auth-form'>
                <div className='text-center mb-3'>
                  <Link to='/login'><img src={logo} /></Link>
                </div>
                <h4 className='text-center mb-4 text-vivaro'>Bienvenido a Vivaro</h4>
                <form action='' onSubmit={handleSubmit(onSubmit)}>
                  <div className='form-group mb-4'>
                    <label className='mb-1 text-vivaro'><strong>Email</strong></label>
                    <input type='email' className='form-control' name='username' placeholder='email@ejemplo.com' {...register("username", {required: true})}/>
                    {errors?.username?.type === "required" && <span className="text-danger">Este campo es obligatorio</span>}
                  </div>
                  <div className='form-group mb-4'>
                    {/* <div className=' d-flex justify-content-between'>
                      <label className='text-vivaro'><strong>Contraseña</strong></label>
                        <Link className='text-vivaro' to='/forgot-password'>Olvidé mi contraseña</Link>
                    </div> */}
                    <label className='mb-1 text-vivaro'><strong>Contraseña</strong></label>
                    <input type='password' className='form-control' name='password' placeholder='contraseña' {...register("password", {required: true})}/>
                    {errors?.password?.type === "required" && <span className="text-danger">Este campo es obligatorio</span>}
                  </div>
                  {error ?
                    <Alert variant='danger' className='alert-dismissible fade show'>
                      {error}
                    </Alert>
                    :
                    <></>
                  }
                  <div className='text-center mt-2'>
                    <Button variant="" type='submit' className='bg-vivaro text-white btn-block'>
                      Ingresar
                    </Button>
                    {/* <Link className='btn bg-vivaro text-white btn-block' to=''>Ingresar</Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    //  </div>
    // </div>
  )
}

export default Login
