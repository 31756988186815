import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import { AuthProvider } from '../auth/AuthProvider.js';
import ProtectedRoute from './ProtectedRoute.js';

/// Css
import '../jsx/index.css'
import '../jsx/chart.css'
import '../jsx/step.css'

/// Layout
import Nav from '../jsx/layouts/nav'
import Footer from '../jsx/layouts/Footer'
import ModalLogin from '../components/Login/ModalLogin.js';


// LazyLoad Components
// Pages live in src/views
const routes = [
  {
    url: '',
    comp: ProtectedRoute,
    children: [
      {
        url: '',
        comp: React.lazy(() => import('../views/movies/MoviesListPage.js'))
      },
      {
        url: 'dashboard',
        comp: React.lazy(() => import('../views/dashboard/DashboardPage.js'))
      },
      {
        url: 'home/banners',
        comp: React.lazy(() => import('../views/home/HomeBannersPage.js'))
      },
      {
        url: 'home',
        comp: React.lazy(() => import('../views/home/HomeBannersPage.js'))
      },
      {
        url: 'home/bloques',
        comp: React.lazy(() => import('../views/home/HomeBlocksPage.js'))
      },
      {
        url: 'movies',
        comp: React.lazy(() => import('../views/movies/MoviesListPage.js'))
      },
      {
        url: 'movies/peliculas-detalle/:contentId',
        comp: React.lazy(() => import('../views/movies/MoviesDetailPage.js'))
      },
      {
        url: 'series',
        comp: React.lazy(() => import('../views/series/SeriesListPage.js'))
      },
      {
        url: 'series/series-detalle/:contentId',
        comp: React.lazy(() => import('../views/series/SeriesDetailPage.js'))
      },
      {
        url: 'series/episodios-detalle/:contentId',
        comp: React.lazy(() => import('../views/series/EpisodesDetailPage.js'))
      },
      {
        url: 'catalogos/directores',
        comp: React.lazy(() => import('../views/catalogos/Characters/DirectorsListPage.js'))
      },
      {
        url: 'catalogos/actores',
        comp: React.lazy(() => import('../views/catalogos/Characters/ActorsListPage.js'))
      },
      {
        url: 'catalogos/personajes',
        comp: React.lazy(() => import('../views/catalogos/Characters/CharactersListPage.js'))
      },
      {
        url: 'catalogos/premios',
        comp: React.lazy(() => import('../views/catalogos/awards/AwardsListPage.js'))
      },
      {
        url: 'catalogos/nominations',
        comp: React.lazy(() => import('../views/catalogos/awards/NominationsListPage.js'))
      },
      {
        url: 'catalogos/classifies',
        comp: React.lazy(() => import('../views/catalogos/content/FilmsClassifiesListPage.js'))
      },
      {
        url: 'catalogos/dealers',
        comp: React.lazy(() => import('../views/catalogos/content/DealersListPage.js'))
      },
      {
        url: 'catalogos/rights',
        comp: React.lazy(() => import('../views/catalogos/rights/RightsPage.js'))
      },
      {
        url: 'catalogos/genres',
        comp: React.lazy(() => import('../views/catalogos/content/GenresListPage.js'))
      },
      {
        url: 'users',
        comp: React.lazy(() => import('../views/users/front/FrontUserListPage.js'))
      },
      {
        url: 'comments',
        comp: React.lazy(() => import('../views/users/comments/CommentsListPage.js'))
      },
      {
        url: 'minisites',
        comp: React.lazy(() => import('../views/minisites/MiniSitesListPage.js'))
      },
      {
        url: 'minisites/detalle/:contentId',
        comp: React.lazy(() => import('../views/minisites/MiniSitesDetailPage.js'))
      },
      {
        url: 'settings/categories',
        comp: React.lazy(() => import('../views/settings/categories/CategoriesPage.js'))
      },
      {
        url: 'settings/categories',
        comp: React.lazy(() => import('../views/settings/categories/CategoriesPage.js'))
      },
      {
        url: 'blog',
        comp: React.lazy(() => import('../views/blog/BlogPage.js'))
      },
      {
        url: 'catalogos/blocks',
        comp: React.lazy(() => import('../views/blocks/ContentBlocksListPage.js'))
      },
      {
        url: 'blocks-detail',
        comp: React.lazy(() => import('../views/blocks/BlocksDetailPage.js'))
      },
      {
        url: 'metrics/royalties',
        comp: React.lazy(() => import('../views/metrics/MetricsRoyalitiesPage.js'))
      },
      {
        url: 'metrics/downloads',
        comp: React.lazy(() => import('../views/metrics/MetricsDownloadsPage.js'))
      },
      {
        url: 'promocodes',
        comp: React.lazy(() => import('../views/promocodes/PromotionalCodesPage.js'))
      },
      {
        url: 'ratings',
        comp: React.lazy(() => import('../views/ratings/RatingsAndCommentsPage.js'))
      },
      {
        url: 'catalogos/awards-categories',
        comp: React.lazy(() => import('../views/awards/awardsCategoriesPage.js'))
      },
      {
        url: 'catalogos/awards',
        comp: React.lazy(() => import('../views/awards/awardsPage.js'))
      }
    ]
  },
  {
    url: 'login',
    comp: React.lazy(() => import('../jsx/pages/Login.js'))
  },
  {
    url: 'forgot-password',
    comp: React.lazy(() => import('../jsx/pages/ForgotPassword.js'))
  },
  {
    url: 'recover-password',
    comp: React.lazy(() => import('../jsx/pages/RecoverPassword.js'))
  },
  {
    url: 'logout',
    comp: React.lazy(() => import('../views/logout/LogoutPage.js'))
  }
];

const RoutesAuth = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  // console.log("pathIndex", path);


  let pagePaths = ['login', 'forgot', 'recover', 'page'];
  let pagePath = pagePaths.includes(path.split('-')[0])

  // let pagePath = path.split('-').includes(['login'])


  const [activeEvent, setActiveEvent] = useState(!path)

  return (
    <Router basename="">

      <AuthProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <div id={`${!pagePath ? "main-wrapper" : ""}`} className={`${!pagePath ? "show menu-toggle" : "h-100 "}`}>
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div className={` ${!path && activeEvent ? "rightside-event" : ""} ${!pagePath ? "content-body" : "authincation h-100 bg-vivaro-2"}`}>
              {/* <div className={`${!pagePath ? "container-fluid" : "container h-100"}`} style={{ minHeight: window.screen.height - 60 }}> */}
              <div className={`${!pagePath ? "container-fluid" : "container h-100"}`}>
                <Routes>
                  {routes.map((data, i) => (
                    <Route key={i} exact path={`/${data.url}`} element={<data.comp />}>
                      {data.children && (
                        data.children.map((item, idx) => (
                          <Route key={i + idx} path={`/${item.url}`} element={<item.comp />} />
                        ))
                      )}
                    </Route>
                  ))}
                </Routes>
              </div>
            </div>
            {!pagePath && <Footer />}
            {!pagePath && <ModalLogin />}
          </div>
        </Suspense>
      </AuthProvider>

    </Router>
  );
}

export default RoutesAuth;
