import React, { createContext, useContext, useState } from 'react';

const LoginContext = createContext({
    loginModal: false,
    updateModalState: ()=>{},
});

export function useLogin() {
    return useContext(LoginContext);
}

export const LoginProvider = ({ children }) => {
    // Now we will use an object to hold the search term and the clean state.
    const [loginModal, setLoginModal] = useState(false);

    // Function to update both the search term and the clean indicator.
    const updateModalState = (state) => {
        setLoginModal(state)
    };

    return (
        <LoginContext.Provider value={{ loginModal, updateModalState }}>
            {children}
        </LoginContext.Provider>
    );
};
