import React, { useState } from 'react';
import { useSearch } from '../../../context/SearchContext';

const SearchBox = () => {

    const [input, setInput] = useState(''); // State to store the input value
    const { updateSearchTerm } = useSearch(); // Get the updateSearchTerm function from the context

    const handleSearch = () => { // Handle the search button click
        updateSearchTerm(input); // Call the updateSearchTerm function with the input value
    };

    const handleKeyDown = (event) => { // Handle the Enter key press
        if (event.key === 'Enter') {
            updateSearchTerm(input);
        }
    };

    const handleChange = (event) => { // Handle the input change
        const value = event.target.value;
        setInput(value);
        // If the input is empty, update the search term to an empty string
        if (value.trim() === '') {
            updateSearchTerm('');
        }
    };

    const renderInputBox = () => {
        return (
            <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={input}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                
            />
        );
    }

    const renderSearchButton = () => {
        return (
            <div className="input-group-append">
                <span className="input-group-text">
                    <a href="#" onClick={handleSearch}>
                        <i className="flaticon-381-search-2" />
                    </a>
                </span>
            </div>
        );
    }   

    return (
        <div className="input-group search-area d-xl-inline-flex">
            {renderInputBox()}
            {renderSearchButton()}
        </div>
    );
};

export default SearchBox;